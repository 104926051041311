import { useEffect } from 'react';

import './Contacts.css';
import photo from '../../Media/photo.png'


function Contacts() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='contacts'>
      <img className='contacts_image' src={photo} alt='' />
      <div className='contacts_texts'>
        <h1 className='title contacts_title'>Добро пожаловать в&nbsp;медиатеку</h1>
        <div className='contacts_text'>
          <h3 className='title contacts_subtitle'>Для получения дополнительной информации обратитесь в&nbsp;медиатеку.</h3>
          <p>Медиатека распологается на <b>2 этаже</b> музея. Мы будем рады предоставить вам всю доступную информацию о заинтересовавших вас событиях и ответить на любые вопросы</p>
          <p>Вы можете поделиться информацией о героях или событиях, не предствленных в музее.
            Для этого обратитесь к сотрудникам медиатеки или напишите письмо с информацией на <b>hero@hero-museum.ru.</b></p>
        </div>
      </div>
    </div>
  );
}

export default Contacts
