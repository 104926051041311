export const baseUrl = 'http://88.212.254.189'
// export const baseUrl = '/backend'

class Api {
  constructor(options) {
    this._address = options.baseUrl;
    this._headers = options.headers;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(`${res.status}`);
  }

  getInfoByUserId(userId) {
    // const tmpId = 'dba32eed-058b-47aa-82fc-c3e328f0148d'
    return fetch(`${this._address}/user-mentor/getUserData?UserId=${userId}`, {
      method: 'GET',
      headers: this._headers,
    })
      .then(this._checkResponse)
  }
}
const api = new Api({
  baseUrl: `${baseUrl}/api`,
  headers: {
    'Accept': '*/*',
    'Content-Type': 'application/json',
  },
})
export default api;
