import { baseUrl } from '../../../Utils/Api';

function Hero({ hero }) {

  return (
    <div className="heroContainer">
      <h1 className='heroContainer_title'>Ваш наставник</h1>
      {hero.Image && <img className='heroContainer_image' src={`${baseUrl}${hero.Image}`} alt='' />}
      <div className='heroContainer_name'>
        <h2 className='title'>{hero.Name}</h2>
        <p className='heroContainer_profession'>{hero.Profession}</p>
      </div>

    </div>
  );
}

export default Hero;