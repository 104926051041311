import { useNavigate } from 'react-router-dom';

function Menu() {
  const navigate = useNavigate()

  return (
<div className='menu'>
  <div className='menu_option' onClick={() => navigate('/favs')}>Понравившиеся материалы</div>
  <div className='menu_option' onClick={() => navigate('/articles')}>Материалы для изучения</div>
  <div className='menu_option' onClick={() => navigate('/tell_us')}>РАССКАЗАТЬ О ПОДВИГЕ</div>
</div>
  );
}

export default Menu;