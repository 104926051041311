import { useEffect } from 'react';
import '../Favs.css';
import { baseUrl } from '../../../Utils/Api';

function Article({ item, setSelectedItem }) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='article'>
      <div className='article_backBtn' onClick={() => {setSelectedItem(undefined)}}></div>
      <img className='article_image' src={`${baseUrl}${item.Image}`} alt='' />
      <div className='article_texts'>
      <h1 className='title article_title'>{item.Name}</h1>
      <h3 className='title article_subtitle'>{item.ShortDescription}</h3>
      <p>{item.LongDescription}</p>
      </div>
    </div>

  );
}

export default Article
