import { useState, useEffect } from 'react';
import { Route, Routes, useSearchParams, useNavigate } from 'react-router-dom';
import api from '../../Utils/Api';
import './App.css';

import MainPage from '../MainPage/MainPage'
import Header from '../Header/Header';
import Mentions from '../MentionsPage/Mentions';
import Favs from '../FavsPage/Favs';
import Contacts from '../ContactsPage/Contacts';


function App() {
  const [hero, setHero] = useState({})
  const [articles, setArticles] = useState([])
  const [mentions, setMentions] = useState([])
  const [id, setId] = useState('')
  const [date, setDate] = useState('')
  const [params] = useSearchParams()
  const navigate = useNavigate()


  const userId = params.get('userId')
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  useEffect(() => {
    console.log(userId)
    if (userId) {
      navigate('/')
      setId(userId)
      localStorage.setItem('userId', userId)
    } else {
      setId(localStorage.getItem('userId'))
    }
  }, [])

  useEffect(() => {
    if (id) {
      api.getInfoByUserId(id)
        .then((res) => {
          console.log(res);
          setHero(res.Hero)
          setArticles(res.Articles)
          setMentions(res.Mentions)
          let fullDate = new Date(res.Date).toLocaleDateString('ru-Ru', options)
          fullDate = fullDate.slice(0, -3)
          setDate(fullDate)
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id])

  return (
    <div className="App">
      {id ? <>
        <Header date={date} />
        <Routes>

          <Route path='/articles'
            element={
              <Mentions mentions={mentions} />
            }
          >
          </Route>
          <Route path='/favs'
            element={
              <Favs articles={articles} />
            }
          >
          </Route>
          <Route path='/tell_us'
            element={
              <Contacts />
            }
          >
          </Route>
          <Route path='/'
            element={
              <MainPage hero={hero} />
            } />
        </Routes>
      </> :
        <h1 className='noIdText'>Сканируйте QR-код
          камерой вашего
          устройства </h1>}
    </div>

  );
}

export default App;
