import { useEffect } from 'react';
import './MainPage.css';
import Hero from './HeroBlock/Hero'
import Menu from './Menu/Menu';

function MainPage({ hero }) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Hero hero={hero} />
      <Menu />
    </>
  );
}

export default MainPage;
