import { useEffect } from 'react';
import './Mentions.css';
import { baseUrl } from '../../Utils/Api';


function Mentions({ mentions }) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='mentions'>
      {mentions.map((item, index) => (
        <div key={index} className='mention'>
          <img className='mention_image' src={`${baseUrl}${item.Image}`} alt='' />
          <div>
            <p className='title mention_title'>{item.Text1}</p>
            {item.Text2 && <p className='title mention_text'>{item.Text2}</p>}
          </div>
        </div>
      ))}

    </div>
  );
}

export default Mentions
