
import { useState, useEffect } from 'react';
import './Favs.css';
import { baseUrl } from '../../Utils/Api';
import Article from './Article/Article';

function Favs({ articles }) {
  const [selectedItem, setSelectedItem] = useState(undefined)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {!selectedItem && articles &&
      <div className='favs'>
        {articles.map((item, index) => (
          <div className='fav' key={index} onClick={() => { setSelectedItem(item) }}>
            <img className='fav_image' src={`${baseUrl}${item.AlternativeImage}`} alt='' />
            <h3 className='title fav_text'>{item.Name}</h3>
          </div>
        ))}

      </div>}
      {selectedItem && <Article item={selectedItem} setSelectedItem={setSelectedItem} />}
    </>
  );
}

export default Favs
