import { useLocation, useNavigate } from 'react-router-dom';

import './Header.css';
import logo from '../../Media/MuseumLogo.svg'
import backIcon from '../../Media/backBtn.svg'

function Header({ date }) {
  const location = useLocation();
  const navigate = useNavigate()
  let isBackBtnVisible = false


  const title = () => {
    switch (location.pathname) {
      case '/favs':
        isBackBtnVisible = true
        return 'Понравившиеся материалы'
      case '/articles':
        isBackBtnVisible = true
        return 'Материалы для изучения'
      case '/tell_us':
        isBackBtnVisible = true
        return 'Рассказать о подвиге'

      default:
        isBackBtnVisible = false
        return date
    }
  }


  return (
    <div className="header">
      <img className='header_logo' src={logo} alt='' onClick={() => navigate('/')} />
      <div className='header_info'>
        <p>{title()}</p>
      </div>
      {isBackBtnVisible && <img className='header_back' src={backIcon} alt='' onClick={() => navigate('/')} />}
    </div>
  );
}

export default Header;
